import styled from 'styled-components';
import {screenSize} from '../../constants/styleConstants';
import { InPageAlert, Button } from '@snsw-gel/react';

export const SavePreferenceContainer = styled.div`
    max-width: ${screenSize.desktop};
    display: flex;
    margin: auto;
    flex-direction: column;
    padding: 32px 0px;
    @media only screen and (max-width: ${screenSize.tablet}) {
        padding: 16px;
        align-items: center;
    }
`;

export const SaveButton = styled(Button)`
    width: 12.5rem;
`;

export const ErrorAlert = styled(InPageAlert)`
    width: 80%;
    margin-top: 10px;

    @media only screen and (min-width: ${screenSize.tablet}) {
        margin-right: 56px;    
        margin-bottom: 0;
    }

    @media only screen and (max-width: ${screenSize.tablet}) {
        width: 100%;
        margin-bottom: 16px;
    }
`;