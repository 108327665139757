import React, {useState, useCallback} from 'react';
import {Modal, FormGroup, Textarea} from '@snsw-gel/react';
import PropTypes from 'prop-types';
import {CharactersLimitDiv} from './Modal.styled';
import utilities from '../../services/utilities';
import {VisuallyHiddenDiv} from '../preferencesPage/PreferencesPage.styled';

export const FeedbackModal = (props) => {

    const limit = 500; // total number of characters that users can put in
    const threshold = 50; // remaining characters that screen reader start reading number of characters left
    const characterCountHint = 'You can enter up to ' + limit + ' characters';

    const [characterCountText, setCharacterCountText] = useState(`Maximum characters ${limit}`);
    const [content, setContent] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [characterCountLiveInfo, setCharacterCountLiveInfo] = useState('');

    const setFormattedContent = useCallback(text => {
        setContent(text.slice(0, limit));
        setErrorMessage('');
        let remainingCharacterCount = limit - text.length;
        remainingCharacterCount = remainingCharacterCount < 0 ? 0 : remainingCharacterCount;
        const string = remainingCharacterCount + (remainingCharacterCount <= 1 ? ' character ' : ' characters ') + 'left';
        setCharacterCountText(string);
        if (remainingCharacterCount <= threshold) {
            const liveInfo = 'You have ' + string;
            setCharacterCountLiveInfo(liveInfo);
        } else {
            setCharacterCountLiveInfo('');
        }
    },[limit, setContent]);

    const submitFunction = () => {
        if (content.length === 0) {
            setErrorMessage('Please enter your feedback');
            document.getElementById('feedback-textarea').focus();
        } else {
            //send data to One Gov
            try {
                utilities.addComment(content, 'Notifications-DRN', 'Notifications-DRN-OptOut').then();
            } catch (err) {
                console.info(err);
            }
            // utilities.addComment(content, 'Notifications-DRN', 'Notifications-DRN-OptOut')
            //     .catch((err) => {
            //         console.info(err);
            //     });
            document.getElementById('modal-portal').style.display = 'none';
            closeModal();
            props.setShowThankYouModal(true);
        }
    };

    const closeModal = () => {
        props.setShowFeedbackModal(false);
        window.scroll({
            top: 0,
            left: 0
        });
    };

    return (
        <>
            <Modal
                title='Your feedback is welcomed'
                onClose={ closeModal }
                buttons={ [
                    { text: 'Send', onClick: submitFunction }
                ] }
            >
                <div>Please let us know why you&apos;ve opted out.
                    We&apos;ll use your feedback to help improve notifications.</div>
                <div style={ {paddingTop: '10px'} }>If you need a response, <a href='https://www.service.nsw.gov.au/contact-us' target='_blank' rel='noreferrer'>send an enquiry</a> instead.</div>

                <FormGroup
                    errorMessage= { errorMessage }
                    id='feedback-textarea'
                    hasError={ errorMessage.length !== 0 }
                    label='Please provide your feedback'
                >
                    <Textarea
                        onChange={ event => setFormattedContent(event.target.value) }
                        aria-invalid={ errorMessage.length !== 0 }
                        value={ content }
                        maxlength={ limit }
                        aria-label={ characterCountHint }
                    />
                </FormGroup>
                <CharactersLimitDiv aria-hidden={ true }>{characterCountText}</CharactersLimitDiv>
            </Modal>
            {/* These divs below are for accessibility:
                Sighted reader users will see character count text, and not other information
                Screen reader users will hear the character hint text when they first interact with a textarea.
                When they start typing, they wont hear anything live
                until they reach the threshold (ie 90% of the character limit).*/}
            <VisuallyHiddenDiv data-testid={ 'visually-hidden-character-count-hint' }>{characterCountHint}</VisuallyHiddenDiv>
            <VisuallyHiddenDiv data-testid={ 'visually-hidden-character-count-live' }
                aria-live={ 'polite' }>{characterCountLiveInfo}</VisuallyHiddenDiv>
        </>
    );
};

FeedbackModal.propTypes = {
    setShowFeedbackModal: PropTypes.func,
    setShowThankYouModal: PropTypes.func
};