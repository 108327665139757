import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {AlertNotification} from './Alert.styled';
import DOMPurify from 'dompurify';
import TagManager from 'react-gtm-module';
import {getTagManagerAlertView, getTagManagerArgs} from '../../googleAnalyticsUtils';
import {sanitize} from '../../services/utilities';

const alertPurifier = new DOMPurify();
const sanitizer = sanitize(alertPurifier);

const handleAlertLinkClick = (event, alertType, serviceName) => {
    if (event.target.hasAttribute('href')) {
        const linkText = event.target.innerHTML;
        TagManager.dataLayer(getTagManagerArgs('link-click', null,
            serviceName + ' - ' + alertType + ' - ' + linkText));
    }
};

const UserActionAlert = ({alert, serviceTitle}) => {
    function userActionLinkClickTrack() {
        TagManager.dataLayer(getTagManagerArgs('accordion-link-click', null,
            serviceTitle.toLowerCase() + ' | ' + alert.actionLinkText.toLowerCase()));
    }

    return (
        <p style={ {margin: 0, paddingTop: '16px'} }>
            <span aria-hidden='true'>{alert.title} </span>
            <a
                style={ { textDecoration: 'underline'} }
                href={ alert.actionLinkHref }
                target='_blank'
                rel='noreferrer'
                aria-label={ alert.title + ' ' + alert.actionLinkText }
                onClick={ () => userActionLinkClickTrack() }
            >{alert.actionLinkText}
            </a>
        </p>
    );
};

const Alert = ({alert, headingLevel, serviceTitle}) => {
    const AlertVariant = (alertType) => {
        switch (alertType) {
        case 'DUPLICATE_ACCOUNT': return 'warning';
        case 'OPT_IN': return 'info';
        case 'OPT_OUT': return 'warning';
        case 'INELIGIBLE': return 'error';
        case 'ERROR': return 'error';
        }
    };

    useEffect(() => {
        let canSendErrorToGA = false;
        if (alert.type === 'ERROR' && (alert.errorType === 'BFF_DOWN_ERROR' ||
            alert.errorType === 'RETRIEVING_PROFILE_ERROR' || alert.errorType === 'RETRIEVING_SERVICES_ERROR')) {
            canSendErrorToGA = true;
        }
        if (canSendErrorToGA) {
            TagManager.dataLayer(getTagManagerAlertView(`error - ${alert.title}`, null, null));
        }
    }, []);

    const getComponent = () => {
        if (alert.type === 'USER_ACTION') {
            return (<><UserActionAlert alert={ alert } serviceTitle={ serviceTitle }/></>);
        }
        return (
            <>
                <AlertNotification headingElement={ headingLevel } variant={ AlertVariant(alert.type) }
                    title={ alert.title } role='alert'>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <div data-testid='alert-description'
                        onClick={ event => handleAlertLinkClick(event, alert.type, serviceTitle) }
                        dangerouslySetInnerHTML={ {__html: sanitizer(atob(alert.description))} }/>
                </AlertNotification>
            </>
        );
    };
    return getComponent();
};

Alert.propTypes = {
    alert: PropTypes.object,
    headingLevel: PropTypes.string,
};

UserActionAlert.propTypes = {
    alert: PropTypes.object,
    serviceTitle: PropTypes.string,
};

export default Alert;
