import React from 'react';
import * as ReactDOM from 'react-dom';
import { PreferencesPage } from './PreferencesPage';
import {ThemeProvider} from 'styled-components';
import {GlobalStyle, theme} from '@snsw-gel/react';
import TagManager from 'react-gtm-module';
import {BrowserRouter, Route} from 'react-router-dom';

export class PreferencesPageComponent extends HTMLElement {

    constructor() {
        super();
        //console.log('Constructing preference component ');
    }

    connectedCallback(){

        this.configGA();

        ReactDOM.render(
            <React.StrictMode>
                <ThemeProvider theme={ theme }>
                    <GlobalStyle />
                    <BrowserRouter>
                        <Route path={ '/' } component={ PreferencesPage }/>
                        <Route path={ '/notifications' } component={ () => {
                            window.location.replace(`${process.env.NOTIFICATIONS_INBOX_URL}`);
                            return null;
                        } }/>
                    </BrowserRouter>
                </ThemeProvider>
            </React.StrictMode>,
            document.getElementById('preferences-page-component')
        );
    }

    disconnectedCallback(){
        ReactDOM.unmountComponentAtNode(this);
    }

    configGA(){
        const path = window.location.pathname;
        if(path !== '/manage/notification-preferences'){

            TagManager.initialize({gtmId: 'GTM-TMQQTN'});//This is for google 360

            // TODO: Remove gtmIdOfGA3 when GA3 is deprecated.
            const gtmIdOfGA3 = (`${process.env.REACT_APP_HOME_URL}` === 'notification-preferences.service.nsw.gov.au')
                ? 'GTM-N56Q3QK' : 'GTM-W2D98VT';
            TagManager.initialize({gtmId: gtmIdOfGA3});
        }
    }
}
