import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../alert/Alert';
import TagManager from 'react-gtm-module';
import {getTagManagerArgs} from '../../googleAnalyticsUtils';
import {Details, DetailsContainer, DetailsElement, Detail} from './DetailsBox.styled';
import {IconEmail, IconMobile, Button} from '@snsw-gel/react';

const formatNumber = (value = '') => {
    if (!value || value === 'null') {
        return 'not added';
    }
    if (value.startsWith('+61')) {
        value = value.replace(/^\+61/g, 0);
    }
    return value.replace(/\s/g, '').replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
};

const linkClickTrack = () => {
    TagManager.dataLayer(getTagManagerArgs('link-click', 'contact details',
        'update contact details'));
};

const personalDetailsUrl = `${process.env.REACT_APP_PERSONAL_DETAILS_URL}`;

const DetailsBox = (props) => {

    return (
        <div aria-labelledby={ 'detailsRegion' }>
            <span>Check your contact details are up to date.</span>
            { props.detailsError ?
                <Alert
                    headingLevel='h3'
                    alert={ props.detailsError }
                /> :
                <DetailsContainer>
                    <Details>
                        <DetailsElement>
                            <span style={ {paddingTop: '0.1875rem'} }><IconMobile /></span>
                            <Detail>
                                <span id='mobileLabel'><strong>Mobile number:</strong></span>
                                <span className='value' data-testid={ 'mobileField' }>{ formatNumber(props.mobile) }</span>
                            </Detail>
                        </DetailsElement>
                        <DetailsElement>
                            <span style={ {paddingTop: '0.1875rem'} }><IconEmail /></span>
                            <Detail>
                                <span id='emailLabel'><strong>Email address:</strong></span>
                                <span className='value' data-testid={ 'emailField' }>{ props.email }</span>
                            </Detail>
                        </DetailsElement>
                    </Details>
                    <Button
                        onClick={ () => linkClickTrack() }
                        href={ personalDetailsUrl }
                        target='_blank'
                        variant='arrow'
                    >Update contact details</Button>
                </DetailsContainer>
            }
        </div>
    );
};

DetailsBox.propTypes = {
    email: PropTypes.string,
    mobile: PropTypes.string,
    detailsError: PropTypes.object,
};

export default DetailsBox;