import PropTypes from 'prop-types';
import {Modal} from '@snsw-gel/react';
import React from 'react';

export const ThankyouModal = (props) => {

    const closeModal = () => {
        props.setShowThankYouModal(false);
        window.scroll({
            top: 0,
            left: 0
        });
    };

    return (
        <>
            <Modal
                id='thankyou-modal'
                title='Thank you'
                buttons={ [
                    {text: 'OK', onClick: closeModal}
                ] }
                onClose={ closeModal }
            >
                <div>We&apos;ve received your feedback and we will take it from here.</div>
            </Modal>
        </>
    );
};

ThankyouModal.propTypes = {
    setShowThankYouModal: PropTypes.func
};