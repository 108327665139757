import React, {useEffect, useState} from 'react';
import DetailsBox from '../detailsBox/DetailsBox';
import {apiServices} from '../../services/api-services';
import SaveFooter from '../saveFooter/saveFooter';
import styled from 'styled-components';
import {screenSize} from '../../constants/styleConstants';
import {ServicesBox} from '../serviceBox/ServicesBox';
import Alert from '../alert/Alert';
import {SavedPreferencesAlert} from '../alert/SavedPreferencesAlert';
import TagManager from 'react-gtm-module';
import {getTagManagerAlertView} from '../../googleAnalyticsUtils';
import {FeedbackModal} from '../feedbackModal/FeedbackModal';
import {ThankyouModal} from '../feedbackModal/ThankyouModal';
import {ErrorAlert} from '../saveFooter/saveFooter.styled';
import {IconNotificationError, Breadcrumb} from '@snsw-gel/react';
import {ContactDivide, SaveErrorDiv} from './PreferencesPage.styled';

const Container = styled.div`
        max-width: ${screenSize.desktop};
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
        margin-top: 24px;
    `;
export const savePreferenceErrorTitle = 'Preferences not saved';

export const PreferencesPage = () => {
    const crumbs = [
        {
            content: 'Notifications',
            path: '/notifications'
        },
        {
            content: 'Preferences',
            path: '/manage/notification-preferences'
        }
    ];

    const saveButtonText = 'Save';

    const [saveState, setSaveState] = useState(false);
    const [preferenceConfigs, setPreferenceConfigs] = useState([]);
    const [serviceConsent, setServiceConsent] = useState({});
    const [optInArr, setOptInArr] = useState([]);
    const [optOutArr, setOptOutArr] = useState([]);
    const [customerData, setCustomerData] = useState({email: '', mobile: ''});
    const [pageErrors, setPageErrors] = useState([]);
    const [errorAlert, setErrorAlert] = useState(false);
    const [serviceMap, setServiceMap] = useState({});
    const [expandedAccordionServices, setExpandedAccordionServices] = useState({});
    const [saveButtonLabel, setSaveButtonLabel] = useState(saveButtonText);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showThankYouModal, setShowThankYouModal] = useState(false);
    const [showSaveError, setShowSaveError] = useState(false);

    useEffect(() => {
        setErrorAlert(false);
        setSaveButtonLabel(saveButtonText);
        apiServices.getPreferenceConfigs()
            .then(({data}) => {
                setPreferenceConfigs(data.services);
                setCustomerData({email: data.email, mobile: data.mobile});
                setPageErrors(data.errors);
                const svcMap = {};
                data.services.map((service) => {
                    svcMap[service.serviceCode] = service.name;
                });
                setServiceMap(svcMap);
                setServiceConsent({});
            })
            .catch(() => {
                setPageErrors([{
                    type: 'ERROR',
                    errorType: 'BFF_DOWN_ERROR',
                    title: 'We were unable to load this page',
                    description: btoa('To view and update your preferences, please try again later.'),
                    actionLinkText: null,
                    actionLinkHref: null
                }]);
            });
    }, [saveState]);

    //comment below ga track for BreadCrumb Click as Tristan said this implementation hasn’t been finalised with the Kiama team
    // useEffect(() => {
    //
    //     const onBreadCrumbClick = (e) => {
    //         if(e.target.href.includes('/notifications')){
    //             TagManager.dataLayer(getTagManagerArgs('link-click', 'breadcrumbs',
    //                 'notifications'));
    //         }
    //     };
    //     Array.from(document.querySelectorAll('.BreadcrumbListItem-sc-1kapvo6-2')).forEach(el => {
    //         el.addEventListener('click', onBreadCrumbClick);
    //     });
    //
    //     // return () => {
    //     //     Array.from(document.querySelectorAll('.BreadcrumbListItem-sc-1kapvo6-2')).forEach(el => {
    //     //         el.removeEventListener('click', onBreadCrumbClick);
    //     //     });
    //     // }
    //
    // }, []);

    const updateExpandedAccordionState = (serviceCode, updatedExpandedAccordionState) => {
        const updatedAccordions = {...expandedAccordionServices};
        updatedAccordions[serviceCode] = updatedExpandedAccordionState;
        setExpandedAccordionServices(updatedAccordions);
    };

    // Function to update the expanded state of all accordions
    const changeAllAccordions = (isExpanded) => {
        const updatedAccordionStates = preferenceConfigs.reduce((acc, config) => {
            acc[config.serviceCode] = isExpanded;
            return acc;
        }, {});
        setExpandedAccordionServices(updatedAccordionStates);
    };

    const updateServiceConsent = (serviceCode, consent) => {
        setErrorAlert(false);
        setShowSaveError(false);
        const updatedConsent = {...serviceConsent};
        if (consent === preferenceConfigs.find((pref) => pref.serviceCode === serviceCode).optInStatus) {
            delete updatedConsent[serviceCode];
        } else {
            updatedConsent[serviceCode] = consent;
        }
        setServiceConsent(updatedConsent);
    };

    const savePreferences = () => {
        if (Object.keys(serviceConsent).length === 0) {
            setShowSaveError(true);
        } else {
            setSaveButtonLabel('Saving...');

            apiServices.updatePreferenceConfigs(serviceConsent)
                .then(() => {
                    const newOptInArr = [];
                    const newOptOutArr = [];
                    Object.keys(serviceConsent).map(function (serviceCode) {
                        if (serviceConsent[serviceCode] === true) {
                            newOptInArr.push(serviceMap[serviceCode]);
                        } else {
                            newOptOutArr.push(serviceMap[serviceCode]);
                        }
                    });

                    setOptInArr(newOptInArr);
                    setOptOutArr(newOptOutArr);

                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });

                    setSaveButtonLabel(saveButtonText);
                    setSaveState(!saveState);

                    const hasDRNOptedOut = newOptOutArr.includes('Vehicle registration');
                    if (hasDRNOptedOut){
                        setShowFeedbackModal(true);
                    }

                    TagManager.dataLayer(getTagManagerAlertView(
                        'confirmation - preferences updated', newOptInArr, newOptOutArr));

                })
                .catch(() => {
                    setOptInArr([]);
                    setOptOutArr([]);
                    setErrorAlert(true);
                    setSaveButtonLabel(saveButtonText);
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                    TagManager.dataLayer(getTagManagerAlertView(
                        'error - ' + savePreferenceErrorTitle, null, null));
                });
        }
    };

    return <>
        {/*<Router history={history}>*/}
        <Container>
            <Breadcrumb linksList={ crumbs }/>
            <div aria-labelledby={ 'mainRegion' }>
                {/* eslint-disable-next-line max-len */}
                {showFeedbackModal && <FeedbackModal setShowFeedbackModal={ setShowFeedbackModal } setShowThankYouModal={ setShowThankYouModal }/>}
                {showThankYouModal && <ThankyouModal setShowThankYouModal={ setShowThankYouModal }/>}

                {(optOutArr.length > 0 || optInArr.length > 0) &&
                        (<>
                            <SavedPreferencesAlert optInArr={ optInArr } optOutArr={ optOutArr }/>
                        </>)
                }
                {errorAlert &&
                    <ErrorAlert headingElement='h3' variant={ 'error' } title= { savePreferenceErrorTitle } role='alert'>
                        <div data-testid='error-alert'>
                            <span>This is because of a system error. To update your preferences, try again later.</span>
                        </div>
                    </ErrorAlert>
                }
                <h1 data-testid={ 'pageHeading' } id={ 'mainRegion' }>Notification preferences</h1>
                {pageErrors.find((error) => error.errorType === 'BFF_DOWN_ERROR') ?
                    <Alert headingLevel='h2'
                        alert={ pageErrors.find((error) => error.errorType === 'BFF_DOWN_ERROR') }/> :
                    <>
                        <DetailsBox
                            data-testid='customer-details'
                            email={ customerData.email }
                            mobile={ customerData.mobile }
                            detailsError={ pageErrors.find((error) => error.errorType === 'RETRIEVING_PROFILE_ERROR') }
                        />
                        <ContactDivide aria-hidden='true'/>
                        <ServicesBox
                            preferenceConfigs={ preferenceConfigs }
                            serviceConsent={ serviceConsent }
                            servicesError={ pageErrors.find((error) => error.errorType === 'RETRIEVING_SERVICES_ERROR') }
                            updateServiceConsent={ updateServiceConsent }
                            expandedAccordionServices={ expandedAccordionServices }
                            updateExpandedAccordionState={ updateExpandedAccordionState }
                            changeAllAccordions={ changeAllAccordions }
                        />
                    </>
                }
            </div>
        </Container>
        {/*</Router>*/}
        {showSaveError &&
            <SaveErrorDiv id='no-changes-to-save-error'>
                <IconNotificationError/>
                <span>No changes have been made. Make a change to your preferences before clicking save.</span>
            </SaveErrorDiv>
        }
        <SaveFooter
            savePreferences={ savePreferences }
            saveButtonLabel={ saveButtonLabel }
        />
    </>;
};
