import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    AccordionContainer,
    AccordionContent,
    AccordionDivider,
    AccordionHeaderMainSection,
    ChevronIcon,
    ServiceTitle,
    ServiceNameBox,
    AccordionHeader,
    ServiceActionBox
} from './PreferenceItem.styled';
import Alert from '../alert/Alert';
import { FormRadioGroup, IconChevronDown, IconChevronUp } from '@snsw-gel/react';
import TagManager from 'react-gtm-module';
import {getTagManagerAlertView, getTagManagerArgs} from '../../googleAnalyticsUtils';
import {screenSize} from '../../constants/styleConstants';
import DOMPurify from 'dompurify';
import {sanitize} from '../../services/utilities';

export const savePreferenceHelpText = 'To confirm your preference select Save Preferences at the bottom of the page';

const svgPurifier = new DOMPurify();
svgPurifier.setConfig({USE_PROFILES: {svg: true, svgFilters: true}});
const sanitizer = svgPurifier.sanitize;

const descriptionPurifier = new DOMPurify();
const descriptionSanitizer = sanitize(descriptionPurifier);

const PreferencesItem = (props) => {
    const consentOptions = [
        { value: 'true', label: 'Digital' },
        { value: 'false', label: props.optInType === 'PAPERLESS' ? 'Paper' : 'No notifications' },
    ];
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= parseInt(screenSize.tablet, 10));

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= parseInt(screenSize.tablet, 10));
        };

        window.addEventListener('resize', handleResize);

        // cleanup function removes the event listener when the component is unmounted to avoid memory leaks.
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onChangeEvent = (value) => {
        const updatedConsent = value === 'true';
        const elementName = props.serviceTitle.toLowerCase() + ' | ' + (updatedConsent ? 'opt in' : 'opt out');
        TagManager.dataLayer(getTagManagerArgs('click-checkbox', null, elementName));

        props.updateServiceConsent(props.code, updatedConsent);
    };

    const toggleAccordion = () => {
        if (!props.isExpanded) {
            TagManager.dataLayer(getTagManagerArgs('accordion-click', null, props.serviceTitle.toLowerCase()));
            if (props.alert && props.alert.type !== 'USER_ACTION') {
                TagManager.dataLayer(getTagManagerAlertView(
                    props.serviceTitle.toLowerCase() + ' - ' + props.alert.type.toLowerCase().replace('_', ' '),
                    null, null));
            }
        }
        props.updateExpandedAccordionState(props.code, !props.isExpanded);
    };

    function learnMoreLinkClickTrack(serviceTitle, learnMoreLinkText) {
        TagManager.dataLayer(getTagManagerArgs('accordion-link-click', 'digital communications',
            serviceTitle.toLowerCase() + ' | ' + learnMoreLinkText.toLowerCase()));
    }

    return (
        <AccordionContainer className={ props.isExpanded ? 'openAccordion' : '' } id={ `${props.code}-accordion-container` }>
            <AccordionHeader id={ `${props.code}-accordion-header` }>
                <AccordionHeaderMainSection justifyContent='space-between' alignItems='center'>
                    {isSmallScreen ? (
                        //region Mobile and Tablet layout
                        <><ServiceNameBox>
                            {props.icon != null &&
                                <div
                                    aria-hidden='true'
                                    data-testid={ `${props.code}-icon` }
                                    dangerouslySetInnerHTML={ { __html: sanitizer(atob(props.icon)) } }
                                    style={ { display: 'flex', alignItems: 'center'} }
                                />
                            }
                            <ServiceTitle> {props.serviceTitle} </ServiceTitle>
                        </ServiceNameBox>
                        <ChevronIcon
                            data-testid={ `${props.code}-chevron-icon` }
                            onClick={ toggleAccordion }
                            onKeyDown={ (e) => { // This makes the span clickable with a keyboard
                                if(e.key === 'Enter' || e.key === ' ') { e.preventDefault(); toggleAccordion(); } }
                            }
                            tabIndex={ 0 } // This makes the span focusable
                            role='button'
                            aria-expanded={ props.isExpanded }
                            aria-label={ `${props.serviceTitle} Accordion` }
                        >
                            {props.isExpanded ? <IconChevronUp /> : <IconChevronDown />}
                        </ChevronIcon>
                        <ServiceActionBox>
                            {props.displayCheckbox &&
                                <FormRadioGroup
                                    id={ `${props.code}-radio-group` }
                                    legend=''
                                    name={ `${props.code} OptIn Radio Button` }
                                    vertical={ true }
                                    options={ consentOptions }
                                    onChange={ onChangeEvent }
                                    value={ props.optedIn ? consentOptions[0].value : consentOptions[1].value }
                                    margin={ { top: 'none' } }
                                />
                            }
                            {props.alert !== null && props.alert.type === 'USER_ACTION' &&
                                <Alert headingLevel='h4' alert={ props.alert } serviceTitle={ props.serviceTitle }/>
                            }
                        </ServiceActionBox></>
                    //endregion
                    ) : (
                        //region Desktop Layout
                        <><ServiceNameBox>
                            {props.icon != null &&
                                <div
                                    aria-hidden='true'
                                    data-testid={ `${props.code}-icon` }
                                    dangerouslySetInnerHTML={ { __html: sanitizer(atob(props.icon)) } }
                                    style={ { display: 'flex', alignItems: 'center'} }
                                />
                            }
                            <ServiceTitle> {props.serviceTitle} </ServiceTitle>
                        </ServiceNameBox>
                        <ServiceActionBox>
                            {props.displayCheckbox &&
                                <FormRadioGroup
                                    id={ `${props.code}-radio-group` }
                                    legend=''
                                    name={ `${props.code} OptIn Radio Button` }
                                    vertical={ false }
                                    options={ consentOptions }
                                    onChange={ onChangeEvent }
                                    value={ props.optedIn ? consentOptions[0].value : consentOptions[1].value }
                                    margin={ { top: 'none' } }
                                />
                            }
                            {props.alert !== null && props.alert.type === 'USER_ACTION' &&
                                <Alert headingLevel='h4' alert={ props.alert } serviceTitle={ props.serviceTitle }/>
                            }
                        </ServiceActionBox>
                        <ChevronIcon
                            data-testid={ `${props.code}-chevron-icon` }
                            onClick={ toggleAccordion }
                            onKeyDown={ (e) => { // This makes the span clickable with a keyboard
                                if(e.key === 'Enter' || e.key === ' ') { e.preventDefault(); toggleAccordion(); }
                            } }
                            tabIndex={ 0 } // This makes the span focusable
                            role='button'
                            aria-expanded={ props.isExpanded }
                            aria-label={ `${props.serviceTitle} Accordion` }
                        >
                            {props.isExpanded ? <IconChevronUp /> : <IconChevronDown />}
                        </ChevronIcon></>
                    //endregion
                    )}
                </AccordionHeaderMainSection>
                <div style={ { paddingRight: '16px' } }>
                    {props.alert !== null && !['USER_ACTION', 'OPT_IN', 'OPT_OUT'].includes(props.alert.type) ?
                        <Alert headingLevel='h4' alert={ props.alert } serviceTitle={ props.serviceTitle }/> :
                        (props.alert !== null && props.alert.type === 'OPT_IN' && props.optedIn) ?
                            <Alert headingLevel='h4' alert={ props.alert } serviceTitle={ props.serviceTitle }/> :
                            (props.alert !== null && props.alert.type === 'OPT_OUT' && !props.optedIn) &&
                            <Alert headingLevel='h4' alert={ props.alert } serviceTitle={ props.serviceTitle }/>
                    }
                </div>
            </AccordionHeader>
            {props.isExpanded && (
                <>
                    <AccordionDivider aria-hidden='true'/>
                    {props.aboutService &&
                        <AccordionContent id={ `${props.code}-accordion-content` }
                            data-testid={ `${props.code}-accordion-content` }
                            dangerouslySetInnerHTML={ {__html: descriptionSanitizer(atob(`${props.aboutService}` ))} }/>
                    }
                </>
            )}
        </AccordionContainer>
    );

};

PreferencesItem.propTypes = {
    code: PropTypes.string,
    optedIn: PropTypes.bool,
    showOptedInStatusLabel: PropTypes.bool,
    displayCheckbox: PropTypes.bool,
    serviceTitle: PropTypes.string,
    serviceDescription: PropTypes.string,
    aboutService: PropTypes.string,
    alert: PropTypes.object,
    learnMoreLinkText: PropTypes.string,
    learnMoreLinkHref: PropTypes.string,
    updateServiceConsent: PropTypes.func,
    isExpanded: PropTypes.bool,
    updateExpandedAccordionState: PropTypes.func,
    icon: PropTypes.string,
    optInType: PropTypes.string
};

export default PreferencesItem;
